import { Close, NotificationsActive, PersonAdd, SearchTwoTone } from '@mui/icons-material'
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { apiUrl, currency, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import { useCommonUI } from '../context/UI';
import { DateRangePicker } from 'rsuite';
import { PieChart } from '@mui/x-charts';

function CustomerPage() {

    const {fetchData, postData} = useApiRequest();
    const {openSnackbar} = useCommonUI();
    const {afterToday} = DateRangePicker;
    const [searchTerm, setSearchTerm] = useState('');
    const [bodyData, setBodyData] = useState({search: '', sort: '', app: '', start: 0, end: 0, dtype: "created", store: [], pincode: ''});
    const [sort, setSort] = useState('');
    const [hasApp, setHasApp] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [dateType, setDateType] = useState('created');
    const [selectedStore, setSelectedStore] = useState([]);
    const [selectedPincode, setSelectedPincode] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [page, setPage] = useState(2);
    const [stores, setStores] = useState([]);

    const [addressModalOpen, setAddressModalOpen] = useState(false);
    const [newAddress, setNewAddress] = useState({ name: '', phone: '', address: '', city: '', state: '', pincode: '' });
    const [isSubmittingAddress, setIsSubmittingAddress] = useState(false);
    const [states, setStates] = useState([]);
    const [totalDocs, setTotalDocs] = useState(0);

    const [notiModal, setNotiModal] = useState(false);
    const [notiHeader, setNotiHeader] = useState('');
    const [notiBody, setNotiBody] = useState('');
    const [notiImage, setNotiImage] = useState('');
    const [notiCategory, setNotiCategory] = useState('e3c3632e-a9dd-4799-9bcf-69d5fe4ef075');
    const [isSubmittingNotification, setIsSubmittingNotification] = useState(false);

    const [pieModal, setPieModal] = useState(false);
    const [isLoadingPie, setIsLoadingPie] = useState(false);
    const [pieData, setPieData] = useState(null);

    useEffect(()=>{
        const getStates = async() =>{
            const resp = await fetchData('app/states');
            const resp1 = await fetchData('store/list');
            if(resp1){
                setStores(resp1.data);
            }
            if(resp){
                setStates(resp.data);
            }
        }
        getStates();
    },[fetchData]);

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setSearchTerm('');
        setSort('');
        setHasApp('');
        setSelectedDate(null);
        setDateType('created');
        setSelectedStore([]);
        setSelectedPincode('');
        setBodyData({search: '', sort: '', app: '', start: 0, end: 0, dtype: "created", store: [], pincode: ''});
        setCustomerList([]);
        getData(1, {search: '', sort: '', app: '', start: 0, end: 0, dtype: "created", store: [], pincode: ''});
    }

    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].setHours(0,0,0,0)
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            search: searchTerm, 
            sort: sort, 
            app: hasApp,
            start,
            end,
            dtype: dateType,
            store: selectedStore,
            pincode: selectedPincode
        }
        setBodyData(body);
        setCustomerList([]);
        getData(1, body);
    }

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };

    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('customer/lists/'+page, body);
        if(resp){
            setCustomerList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData])

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setCustomerList([]);
            const resp = await postData('customer/lists/1', {search: '', sort: '', app: '', start: 0, end: 0, dtype: "created", store: [], pincode: ''});
            if(resp){
                setCustomerList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && customerList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, customerList.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const handleNewAddress = (e) => {
        const { name, value } = e.target;
        setNewAddress({ ...newAddress, [name]: value });
    };

    const handleAddressModalClose = () =>{
        if(isSubmittingAddress){
            return;
        }
        setAddressModalOpen(false);
    }

    const submitAddress = async(e) => {
        e.preventDefault();
        setIsSubmittingAddress(true);
        const resp = await postData('customer/new', newAddress);
        if(resp){
            let newCust = resp.data;
            if(page){
                setCustomerList((prevArray) => {
                    const updatedArray = [newCust, ...prevArray.slice(0, prevArray.length - 1)];
                    return updatedArray;
                });
            }else{
                setCustomerList((prevArray) => [newCust, ...prevArray]);
            }
            setAddressModalOpen(false);
            setNewAddress({ name: '', phone: '', address: '', city: '', state: '', pincode: '' });
            openSnackbar("Customer Added", "success");
        }
        setIsSubmittingAddress(false);
    }

    const exportCsv = () =>{
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].getTime()
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            search: searchTerm, 
            sort: sort, 
            app: hasApp,
            start,
            end,
            dtype: dateType
        }
        const url = `${apiUrl}customer/csv?session=${localStorage.getItem('session')}&data=${encodeURIComponent(JSON.stringify(body))}`;
        window.open(url, '_blank');
    }

    const openNotification = () =>{
        setNotiHeader('');
        setNotiBody('');
        setNotiImage('');
        setNotiModal(true);
    }
    const closeNotiModal = () =>{
        if(isSubmittingNotification){
            return;
        }
        setNotiModal(false);
    }
    const sendNoti = async() =>{
        if(notiHeader === "" && notiBody === "" && notiImage === ""){
            openSnackbar("Nothing Entered", "error");
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].getTime()
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        const body = {
            search: searchTerm, 
            sort: sort, 
            app: hasApp,
            start,
            end,
            dtype: dateType
        }
        setIsSubmittingNotification(true);
        const resp = await postData('customer/notifications', {header: notiHeader, body: notiBody, image: notiImage, category: notiCategory, data: body});
        if(resp){
            openSnackbar('Notification sent', "success");
            setNotiModal(false);
        }
        setIsSubmittingNotification(false);
    }
    const handleStore = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedStore(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const loadPie = async() =>{
        setPieModal(true);
        if(pieData){
            return;
        }
        setIsLoadingPie(true);
        const resp = await fetchData("customer/lastorderpie");
        if(resp){
            setPieData(resp.data);
        }
        setIsLoadingPie(false);
    }
    const pieClick = (event, data) =>{
        const item = pieData[data.dataIndex];
        if(item){
            setPieModal(false);
            setSelectedDate([new Date(item.startDate), new Date(item.endDate)]);
            setSearchTerm('');
            setSort('');
            setHasApp('');
            setDateType('lastOrder');
            setSelectedStore([]);
            setSelectedPincode('');

            let start = new Date(item.startDate).getTime();
            let end = new Date(item.endDate).getTime();
            let body = {
                search: '', 
                sort: '', 
                app: '',
                start,
                end,
                dtype: 'lastOrder',
                store: [],
                pincode: ''
            }
            setBodyData(body);
            setCustomerList([]);
            getData(1, body);
        }
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Customers ({totalDocs})</Typography>
                <Stack flexDirection={'row'} gap={2}>
                    <Button variant='contained' onClick={loadPie} color='warning'>Last Orders Pie</Button>
                    <Button variant='contained' onClick={()=>{setAddressModalOpen(true)}} color='error'>Add Customer</Button>
                    <Button variant='contained' onClick={openNotification}>Send Notifications</Button>
                    <Button variant='contained' onClick={exportCsv} color='info'>Export</Button>
                </Stack>
            </Stack>
            <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                <Stack flexDirection='row' gap={2}>
                    <TextField fullWidth variant='outlined' label='Customer Name/Phone' value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                    <FormControl fullWidth>
                        <InputLabel>Date Type</InputLabel>
                        <Select value={dateType} onChange={(e)=>{setDateType(e.target.value)}} >
                            <MenuItem value='created'>Join Date</MenuItem>
                            <MenuItem value='lastOrder'>Last Order Date</MenuItem>
                        </Select>
                    </FormControl>
                    <Stack>
                        <Box width={250}></Box>
                        <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                    </Stack>
                    <FormControl fullWidth>
                        <InputLabel>Sort</InputLabel>
                        <Select value={sort} onChange={(e)=>{setSort(e.target.value)}} >
                            <MenuItem value='wasc'>Wallet Ascending</MenuItem>
                            <MenuItem value='wdsc'>Wallet Descending</MenuItem>
                            <MenuItem value='loasc'>Last Order Ascending</MenuItem>
                            <MenuItem value='lodsc'>Last Order Descending</MenuItem>
                            <MenuItem value='lono'>No Orders Made</MenuItem>
                            <MenuItem value='nostore'>No Store</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Store</InputLabel>
                        <Select multiple value={selectedStore} onChange={handleStore} input={<OutlinedInput label="Chip" />} >
                            {stores.map(store=>{
                                return <MenuItem key={store.id} value={store.id}>{store.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>App</InputLabel>
                        <Select value={hasApp} onChange={(e)=>{setHasApp(e.target.value)}} >
                            <MenuItem value='yes'>Yes</MenuItem>
                            <MenuItem value='no'>No</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField fullWidth variant='outlined' label='Pincodes (Comma Seperated)' value={selectedPincode} onChange={(e)=>{setSelectedPincode(e.target.value)}} />
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                    <IconButton type='reset'>
                        <Close fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>
            <Stack mt={2.5} flexDirection='row' alignItems='center' width='100%' paddingX={3.2}>
                <Box width='15%'>
                    <Typography><b>Joined</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Customer Name</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Customer Phone</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Wallet</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>App</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Last Order</b></Typography>
                </Box>
                <Box width='25%'>
                    <Typography><b>Address</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Details</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 338px)' overflow='auto' gap={1} ref={containerRef}>
                {customerList.map((customer, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='15%'>
                            <Typography>{moment.utc(customer.createdAt).local().format('Do MMM YYYY h:mm a')}</Typography>
                        </Box>
                        <Box width='15%'>
                            <Typography>{customer.name}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{customer.phone}</Typography>
                        </Box>
                        <Box width='5%'>
                            <Typography>{currency} {customer.wallet}</Typography>
                        </Box>
                        <Box width='5%'>
                            <Typography>{customer.app?"Yes":"No"}</Typography>
                        </Box>
                        <Box width='15%'>
                            {customer.lastOrder && <Typography>{moment.utc(customer.lastOrder).local().format('Do MMM YYYY h:mm a')}</Typography>}
                            {customer.lastOrder && <Typography> {`(${moment().diff(moment.utc(customer.lastOrder), 'days')} days ago)`}</Typography>}
                        </Box>
                        <Box width='25%'>
                            {customer.addresses[0] && <Typography>{customer.addresses[0].address}, {customer.addresses[0].city}, {customer.addresses[0].state.name} - {customer.addresses[0].pincode}</Typography>}
                        </Box>
                        <Box width='10%'>
                            <Link to={`/customers/${customer.id}`}>
                                <Button variant='contained' size='small'>Details</Button>
                            </Link>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Customers</Typography>
                </Stack>}
                {customerList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Customers to show</Typography>
                </Stack>}
            </Stack>

            <Modal open={addressModalOpen} onClose={handleAddressModalClose}>
                <Box component="form" sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitAddress}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add New Customer</Typography>
                        <TextField label="Name" name='name' value={newAddress.name} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={newAddress.phone} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <TextField label="Address" name='address' value={newAddress.address} onChange={handleNewAddress} variant="outlined" fullWidth multiline rows={4} required/>
                        <TextField label="City" name='city' value={newAddress.city} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <FormControl fullWidth required>
                            <InputLabel>State</InputLabel>
                            <Select name="state" value={newAddress.state} onChange={handleNewAddress} variant="outlined">
                                {states.map((state) => {
                                    return <MenuItem key={state} value={state.id}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField label="Pincode" name='pincode' type='number' value={newAddress.pincode} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmittingAddress}  loadingPosition='start' startIcon={<PersonAdd/>}><span>Add New Customer</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={pieModal} onClose={()=>{setPieModal(false)}}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 300px)', width: 600, bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Last Orders Made</Typography>
                        {isLoadingPie && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}} mb={2}>
                            <ClipLoader size={15} color='#000' />
                            <Typography variant='body'>Loading Graph Data</Typography>
                        </Stack>}
                        {pieData && <Box>
                            <PieChart series={[{data: pieData}]} width={550} height={300} onItemClick={pieClick} />
                        </Box>}
                    </Stack>
                </Box>
            </Modal>

            <Modal open={notiModal} onClose={closeNotiModal}>
                <Box sx={{position: 'absolute',top: '10%', bottom: '10%', left: '10%', right: '10%', bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                        <Stack flex={1} p={2} height={'100%'}>
                            <Typography variant='h4'>Push Notifications</Typography>
                            <Typography variant='h6'>{totalDocs} customers selected</Typography>
                            <Typography variant='subtitle'>Customers without app will be excluded</Typography>
                            <Stack gap={2} mt={3} flex={1}>
                                <TextField label="Notification Header" value={notiHeader} onChange={(e)=>{setNotiHeader(e.target.value)}} variant="outlined" fullWidth/>

                                <TextField label="Notification Body" value={notiBody} onChange={(e)=>{setNotiBody(e.target.value)}} variant="outlined" fullWidth multiline rows={4} required/>

                                <TextField label="Notification Image Link" type='url' value={notiImage} onChange={(e)=>{setNotiImage(e.target.value)}} variant="outlined" fullWidth/>

                                <FormControl fullWidth>
                                    <InputLabel>Notification Channel</InputLabel>
                                    <Select label="Notification Channel" value={notiCategory} onChange={(e)=>{setNotiCategory(e.target.value)}} >
                                        <MenuItem value='e3c3632e-a9dd-4799-9bcf-69d5fe4ef075'>Promotion</MenuItem>
                                        <MenuItem value='87bf5eb1-559a-4ab7-904b-4d9f0822da25'>Order Updates</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Box mt={2}>
                                <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmittingAddress}  loadingPosition='start' startIcon={<NotificationsActive/>} onClick={sendNoti}><span>Send Notifications</span></LoadingButton>
                            </Box>
                        </Stack>
                        <Stack flex={1} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                            <Stack className='notiPhone' height={'calc(100% - 100px)'} sx={{marginTop: '10%', marginBottom: '10%', width: '450px'}}>
                                <Box sx={{margin: 'auto', width: '404px', borderRadius: '28px', background: '#fff'}} p={2}>
                                    <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                                        <img src='/assets/onesignal.png' width='20px' alt='' />
                                        <Typography variant='body2'>Dryfi Laundry</Typography>
                                    </Stack>
                                    <Typography my={0.5}><b>{notiHeader}</b></Typography>
                                    <Stack my={0.5}>
                                        {(() => {
                                            const lines = notiBody.split('\n');
                                            return lines.map((line, index) => {
                                                if (index < 3) {
                                                    return <Typography key={index} variant='body'>{line}</Typography>
                                                }
                                                if (index === 3) {
                                                    return <Typography key={index} variant='body'>{line}{lines.length > 4? " ...":"" }</Typography>
                                                }
                                                return null;
                                            });
                                        })()}
                                    </Stack>
                                    {notiImage !== "" && <img src={notiImage} width='100%' alt='' />}
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
        </Stack>
    )
}

export default CustomerPage